<template>
  <div v-if="tag">
    <PageHeader :title="tag.name">
      <div
        :style="`
          background: ${tag.color};
          color: ${getTagTextColor(tag.color)};
        `"
        class="tag tw-text-base tw-inline-block"
      >
        {{ tag.name }}
      </div>
      <span v-if="tag.created_by" class="tw-ml-2 tw-text-sm">
        Gemaakt door {{ tag.created_by.first_name }} {{ tag.created_by.last_name }} op {{ tag.created_on | date-day }}
      </span>
      <div slot="buttons" class="tw-flex-grow tw-max-w-lg">
        <FormulateInput
          v-model="contactSelection"
          type="autocomplete"
          auto-complete-type="contact"
          name="contactAssign"
          placeholder="Contactpersoon toewijzen"
          :params="{ tags_exclude: tag.id }"
          outer-class="tw-my-2"
          @input="assignContact"
        />
      </div>
    </PageHeader>

    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <DataTable
        :loading="loading"
        :headers="headers"
        :infinite-scroll="true"
        v-bind="contacts"
        class="tw-my-8"
        @infiniteLoad="loadTagContacts"
      >
        <template #item-started_on="{ item }">
          {{ item.tag.started_on | date-day }}
        </template>
        <template #item-ended_on="{ item }">
          {{ item.tag.ended_on | date-day }}
        </template>
        <template #item-updated_by="{ item }">
          <span v-if="item.tag.updated_by">
            {{ item.tag.updated_by.first_name }} {{ item.tag.updated_by.last_name }}
          </span>
          <span v-else>-</span>
        </template>
        <template #item-actions="{ item }">
          <button
            title="Communicatie verwijderen"
            type="button"
            class="action tw-bg-danger"
            @click="removeContact(item)"
          >
            <i class="far fa-trash-alt"/>
          </button>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { getTagTextColor } from '@/utils/helpers'

import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable.vue'

import ContactCity from '@/components/contacts/contact_render_components/ContactCity'
import ContactEmail from '@/components/contacts/contact_render_components/ContactEmail'
import ContactGDPR from '@/components/contacts/contact_render_components/ContactGDPR'
import ContactName from '@/components/contacts/contact_render_components/ContactName'

import { getContactsByTagId, getContactTagDetails, addTagToContact, updateContactTag } from '@/services/contacts'
import { successModal, errorModal } from '@/modalMessages'

export default {
  name: 'ContactTagDetails',
  components: {
    PageHeader,
    DataTable
  },
  data () {
    return {
      tag: null,
      loading: false,
      // The contacts object includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      contacts: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      contactSelection: null
    }
  },
  computed: {
    tagId () {
      return this.$route.params.id
    },
    headers () {
      return [
        { text: 'Naam', value: 'id', renderComponent: ContactName },
        { text: 'Woonplaats', value: 'residence', renderComponent: ContactCity },
        { text: 'E-mail', value: 'email', renderComponent: ContactEmail },
        { text: 'Startdatum', value: 'started_on' },
        { text: 'Toegevoegd door', value: 'updated_by' },
        { text: 'GDPR', value: 'gdpr', renderComponent: ContactGDPR },
        { text: 'Actie', value: 'actions' }
      ]
    }
  },
  watch: {
    tagId: {
      immediate: true,
      handler (id) {
        this.loadTag(id)
      }
    }
  },
  methods: {
    getTagTextColor,
    async loadTag (tagId) {
      const response = await getContactTagDetails(tagId)
      this.tag = response.data
      return response
    },
    async loadTagContacts ($infinite, next) {
      try {
        const payload = { tagId: this.tagId }
        if (next) payload.url = next

        const response = await getContactsByTagId(payload)
        const contacts = response.data
        const results = [...this.contacts.results, ...contacts.results]
        this.contacts = { ...contacts, results }

        if (results.length) $infinite.loaded()
        if (!contacts.next) $infinite.complete()
        return response
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },
    async assignContact (contact) {
      try {
        if (!contact) return
        this.contactSelection = ''

        const response = await addTagToContact(contact.id, { tag: this.tag.id })
        this.contacts.results.unshift({ tag: response.data, ...contact })

        successModal(`${contact.display_name} succesvol toegevoegd.`)
        return response
      } catch (error) {
        console.error(error)
        errorModal(`Kan ${contact.display_name} niet toevoegen`, true)
      }
    },
    async removeContact (contact) {
      try {
        this.contacts.results = this.contacts.results.filter(item => item.id !== contact.id)
        return await updateContactTag(contact.id, contact.tag.id, { is_active: false })
      } catch (error) {
        console.error(error)
        errorModal(`Kan ${contact.display_name} niet verwijderen`, true)
      }
    }
  }
}
</script>
