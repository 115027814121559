var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tag)?_c('div',[_c('PageHeader',{attrs:{"title":_vm.tag.name}},[_c('div',{staticClass:"tag tw-text-base tw-inline-block",style:(("\n        background: " + (_vm.tag.color) + ";\n        color: " + (_vm.getTagTextColor(_vm.tag.color)) + ";\n      "))},[_vm._v(" "+_vm._s(_vm.tag.name)+" ")]),(_vm.tag.created_by)?_c('span',{staticClass:"tw-ml-2 tw-text-sm"},[_vm._v(" Gemaakt door "+_vm._s(_vm.tag.created_by.first_name)+" "+_vm._s(_vm.tag.created_by.last_name)+" op "+_vm._s(_vm._f("date-day")(_vm.tag.created_on))+" ")]):_vm._e(),_c('div',{staticClass:"tw-flex-grow tw-max-w-lg",attrs:{"slot":"buttons"},slot:"buttons"},[_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"contact","name":"contactAssign","placeholder":"Contactpersoon toewijzen","params":{ tags_exclude: _vm.tag.id },"outer-class":"tw-my-2"},on:{"input":_vm.assignContact},model:{value:(_vm.contactSelection),callback:function ($$v) {_vm.contactSelection=$$v},expression:"contactSelection"}})],1)]),_c('div',{staticClass:"tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto"},[_c('DataTable',_vm._b({staticClass:"tw-my-8",attrs:{"loading":_vm.loading,"headers":_vm.headers,"infinite-scroll":true},on:{"infiniteLoad":_vm.loadTagContacts},scopedSlots:_vm._u([{key:"item-started_on",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date-day")(item.tag.started_on))+" ")]}},{key:"item-ended_on",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date-day")(item.tag.ended_on))+" ")]}},{key:"item-updated_by",fn:function(ref){
      var item = ref.item;
return [(item.tag.updated_by)?_c('span',[_vm._v(" "+_vm._s(item.tag.updated_by.first_name)+" "+_vm._s(item.tag.updated_by.last_name)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item-actions",fn:function(ref){
      var item = ref.item;
return [_c('button',{staticClass:"action tw-bg-danger",attrs:{"title":"Communicatie verwijderen","type":"button"},on:{"click":function($event){return _vm.removeContact(item)}}},[_c('i',{staticClass:"far fa-trash-alt"})])]}}],null,false,2253816688)},'DataTable',_vm.contacts,false))],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }